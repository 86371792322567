import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import ToggleMute from "../components/ToggleMute"

export default function ContractorAgreementF() {
  const { user, saveUser } = useContext(UserContext)

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Background container>
      <div className="d-flex flex-column align-items-center">
        <h1 className="font-weight-bold mt-4 ml-4">Contractor Agreement</h1>

        <p className="p-4 lead" style={{ marginBottom: 100 }}>
          <strong>17)</strong> As an independent contractor, I owe a duty of
          care to the Customer and any liability due to ALPHAVIBE is separate
          and distinct from my obligations and duties owed to the Customer under
          the terms of this Agreement.
          <br />
          <br />
          <strong>18)</strong> A person who is not a Party to this Agreement
          shall have no rights under the Contracts (Rights of Third Parties) Act
          2001 (Cap 53B) (or similar legislation in any jurisdiction) to enforce
          any terms of this Agreement.
          <br />
          <br />
          <strong>19)</strong> I have read and understood for the requirement
          set out in Appendix 2 for applicable contract job(s).
          <br />
          <br />
          <strong>20)</strong> I have duly filled up Appendix 3 and Appendix 4
          accordingly and hereby declare the information provided is true and
          valid.
          <br />
          <br />
          <strong>21)</strong> I understand that this Agreement is governed by
          the laws of Singapore and I submit to the exclusive jurisdiction of
          the Singapore Courts.
        </p>

        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="I Agree"
            size="medium"
            onClick={() => navigate("/contractor-agreement-1a")}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-e")}
          />
        </div>
      </div>
    </Background>
  )
}
